import React,{useState,useEffect} from 'react'
import WebTemplate from '../Components/WebTemplate'
import { useGetAllProductsQuery } from '../Services/modules/products'
import { Config } from '../Config';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { updateProductDetailsData } from '../Store/ProductDetails';

const Products = () => {
  const navigate=useNavigate()
  const dispatch=useDispatch()
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10); // Set an initial value
  const [productData, setProductData] = useState([]);
  const[total_rows,setTotalRows]=useState()
  // const[totalRows,setTotalRows]=useState()
  // const [totalPages, setTotalPages] = useState(0);

  const{data:dataProduct=[],
    isSuccess:isSuccessProduct,
    isLoading:isLoadingProduct,
    error:errorProduct,
    refetch
  }=useGetAllProductsQuery({page_number:page,page_size:rowsPerPage})

  useEffect(()=>{
    if(isSuccessProduct)
    {
      console.log("All Products data",dataProduct)
      setProductData(prevData => [...prevData, ...dataProduct.data]);
      setTotalRows(dataProduct.totalRows)
    //  setProductData(dataProduct.data)
     // setPage(prevPage => prevPage + 1);
    }
  },[dataProduct])
  const handleScroll = () => {
    if (
      window.innerHeight + document.documentElement.scrollTop !==
      document.documentElement.offsetHeight
    )
      return;
    if (isLoadingProduct) return;
    if (productData.length >= total_rows) return; // Check if all data is already loaded
    console.log("inscrollll")
    setPage(prevPage => prevPage + 1);
    refetch();
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);
  const goToProductDetails=(param)=>{
    dispatch(updateProductDetailsData({data:param}))
    navigate({
      pathname: `/productdetails/${param.product_link}`,
      //search: `?product=${param}`,
    });
  }
  const productsScreen =()=>{
    const classname ='heroProduct d-flex align-items-center'
    return(
    <>
    <section class={classname} style={{height:'60vh'}}>  
<div class="container">
  <div class="row" style={{textAlign:'center',marginTop:"80px"}}>
    <div class="col-lg-12 d-flex flex-column justify-content-center  pt-lg-0" data-aos="fade-up" data-aos-delay="200">
    
      <h1 class="text-center text-overlay" style={{color:'white'}}>OUR PRODUCTS</h1> 
    
      
  
    </div>
  </div>

 
</div>
</section>

<main id="main">
<div class="container" data-aos="fade-up">
<div class="section-title" style={{marginTop:"50px"}}>
      <h2 style={{color:'black',padding:"0px",marginBottom:"0px"}}><span style={{color:"#6b9c2f"}}>Checkout Our Products</span> <span style={{color:"#fb28a4"}}></span> </h2>
    </div>

<div className='row' style={{marginTop:"50px"}}>
      {productData.map((data, index) => 
      {
        return(
          <>
        <div class="col-xl-4 col-md-6 "
         style={{marginBottom:"20px"}} data-aos="zoom-in" data-aos-delay="100"> 
          <a style={{cursor:"pointer"}} onClick={()=>goToProductDetails(data)}>
                <div className="course-card">
        {
                data.product_images.map((image_info,image_index)=>{
                  if(image_info.is_thumbnail_image==true)
                  {   
return(
  <img
                  
  src={`${Config.API_URL_FILE}/ProductPhotos/${image_info.image_path}`}
 // className="product-image"
   alt={`Image ${index}`}
   style={{ width: '25%',height:"10%", cursor:'pointer',objectFit: 'cover' }}
   //onClick={() => handleImageClick(image)}
 />
)
                  }
                })
               }
      <h3 className="course-title" style={{fontSize:"20px",color:'white'}}>
      <span style={{color:"#000"}}>{data.product_name}</span></h3> 
        </div>
        </a>
          </div>
          </>
        
      )}
    )
      }
      {isLoadingProduct && <div>Loading...</div>}
    </div>

</div>
</main>
</>
    
    )
  }
  return (
   <WebTemplate>
    {productsScreen()}
   </WebTemplate>
  )
}

export default Products
