export default build =>
    build.query({
        tagTypes: ['productbylink'],
        keepUnusedDataFor: 1,
        query(payload) {
           
            console.log("in get gallery by Link",payload)
            return {
                url: `/product/getProductByLink?product_link=${payload.product_link}`,           
                method: 'GET',
              //  body: payload,
               
            };
        },
        providesTags: ['productbylink'],
    })
