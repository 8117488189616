import { api } from '../../api'
import getAllProducts from './getAllProducts'
import getProductByLink from './getProductByLink'
import getProductsHomePage from './getProductsHomePage'


export const productApi = api.injectEndpoints({
    endpoints: build => ({
        GetProductsHomePage: getProductsHomePage(build),
        GetAllProducts:getAllProducts(build),
        GetProductByLink:getProductByLink(build)
       
    }),
    overrideExisting: true,
})

export const { 
    useGetProductsHomePageQuery,
    useGetAllProductsQuery,
    useGetProductByLinkQuery
    
 } = productApi

