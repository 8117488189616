import React,{useEffect} from 'react'
//import { useSelector } from 'react-redux'
import { 
    Route,
    Routes,     
    Navigate,
    Outlet, } from 'react-router-dom'

import Home from './Pages/Home'
//import About from './Pages/About';

import Contact from './Pages/Contact';

import Products from './Pages/Products';
import PhotoGallary from './Pages/PhotoGallary';
import PhotoGalleryEvent from './Pages/PhotoGalleryEvent';
import PhotoGalleryPhotos from './Pages/PhotoGalleryPhotos';
import ProductDetails from './Pages/ProductDetails';

function AppRoutes() {
 
  return (
    <>
   
    <Routes>
        <Route index element={<Home/>}/>
                     
        <Route path="/contact" element={<Contact/>}/>
       
        {/* <Route path="/about" element={<About/>}/>               */}
       
        <Route path="/products" element={<Products/>}/>
        <Route path="/productdetails/:textAfterSlash" element={<ProductDetails/>}/>
      
        <Route path='/photogallery' element={<PhotoGalleryPhotos/>}/>
        {/* <Route path='/galleryevent' element={<PhotoGalleryEvent/>}/>
        <Route path='/galleryphotos' element={<PhotoGalleryPhotos/>}/> */}


        

    </Routes>
    </>
  )
}

export default AppRoutes