export const Config = {
   
    // API_URL_LOCAL: 'http://localhost:8000/api/',
    // API_URL_FILE: 'http://localhost:8000',
    
  
    API_URL_LOCAL: 'https://cccbackend.skilluphere.com/api/',
    API_URL_FILE: 'https://cccbackend.skilluphere.com',
   
  }
  