export default build =>
    build.query({
        tagTypes: ['producthomepage'],
        keepUnusedDataFor: 1,
        query(payload) {
           
            console.log("in get gallery by id",payload)
            return {
                url: `/product/getproducthomepage`,           
                method: 'GET',
              //  body: payload,
               
            };
        },
        providesTags: ['producthomepage'],
    })
