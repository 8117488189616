import React,{useState,useEffect} from 'react'
import WebTemplate from '../Components/WebTemplate';
import { useGetAllPhotosQuery, useGetEventsByYearMutation } from '../Services/modules/photogallery';
import { Config } from '../Config';

function PhotoGalleryPhotos() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10); // Set an initial value
  const [photosData, setPhotosData] = useState([]);
  const[years,setYears]=useState([])
  const[events,setEvents]=useState([])
const[isFilter,setIsFilter]=useState(false)
  const[selectedYear,setSelectedYear]=useState("")
  const[selectedEvent,setSelectedEvent]=useState("")
  const[total_rows,setTotalRows]=useState()
  const classname ='heroProduct d-flex align-items-center'
  
  const PhotoGalleryPhotosScreen=()=>{
    const{data:dataPhotos=[],
      isSuccess:isSuccessPhotos,
      isLoading:isLoadingPhotos,
      error:errorPhotos,
      refetch
    }=useGetAllPhotosQuery({page_number:page,page_size:rowsPerPage})
  const [GetEventsByYear, { data, isSuccess, isLoading, isError, error }] = useGetEventsByYearMutation();

  useEffect(()=>{
    console.log("dataPhotos",dataPhotos)
    if(isSuccessPhotos)
    {
      setPhotosData(prevData => [...prevData, ...dataPhotos.data]);
      setTotalRows(dataPhotos.totalRows)
      setYears(dataPhotos.years)
     // setPhotosData(dataPhotos.data)
    }
  },[dataPhotos])
  useEffect(()=>{
    if(isSuccess)
    {
      console.log("Data on yer selection",data)
      setEvents(data.programDetailsWithoutPagination)
    }
  },[data])
  const handleScroll = () => {
    if (
      window.innerHeight + document.documentElement.scrollTop !==
      document.documentElement.offsetHeight
    )
      return;
      if(isFilter) return
    if (isLoadingPhotos) return;
    if (photosData.length >= total_rows) return; // Check if all data is already loaded
    console.log("inscrollll")
    setPage(prevPage => prevPage + 1);
    refetch();
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);
  const handleYearChange = (selectedYear) => {
    console.log("selectedYear",selectedYear)
    setSelectedYear(selectedYear);
    GetEventsByYear({year_id:selectedYear})

  };
  const handleEventChange = (selectedEvent) => {
    
    setSelectedEvent(selectedEvent);
   
    events.map((dataFilter,index)=>{
      if(selectedEvent==dataFilter._id)
      {
        setIsFilter(true)
        console.log("dataFilter",dataFilter)
        setPhotosData(dataFilter.photo_url)
       
      }
    })
   
  };
 
    return(
      <>
 <section class={classname} style={{height:'60vh'}}>  
<div class="container">
  <div class="row" style={{textAlign:'center',marginTop:"80px"}}>
    <div class="col-lg-12 d-flex flex-column justify-content-center  pt-lg-0" data-aos="fade-up" data-aos-delay="200">
    
      <h1 class="text-center text-overlay" style={{color:'white'}}>OUR PRODUCTS</h1> 
    
      
  
    </div>
  </div>

 
</div>
</section>
<main id="main">
<div class="container" data-aos="fade-up">
<div class="row" style={{marginTop:"50px"}}>
  <div className='col-md-4'>
  <select value={selectedYear} 
className='form-control'
onChange={(e) => handleYearChange(e.target.value)}>
      <option value="">All</option>
      {years.map((year) => (
        <option key={year._id} value={year._id}>{year.year}</option>
      ))}
    </select>
  </div>
  <div className='col-md-4'>
  <select value={selectedEvent} 
className='form-control'
onChange={(e) => handleEventChange(e.target.value)}>
      <option value="">All</option>
      {events.map((eventData) => 
      {
        console.log("eventData",eventData)
       return (
        <option key={eventData._id} value={eventData._id}>{eventData.program_name}</option>
      )}
      )}
    </select>
  </div>
 
    </div>

<div className='row' style={{marginTop:"50px"}}>
      {photosData.map((data, index) => 
      {
        
        return(
          <>
        <div class="col-xl-3 col-md-6 d-flex align-items-stretch"
         style={{marginBottom:"20px"}} data-aos="zoom-in" data-aos-delay="100"> 
          <a style={{cursor:"pointer"}} 
         // onClick={()=>goToProductDetails("bnc")}
          >
                <div className="course-card">
          
  <img
                  
  src={`${Config.API_URL_FILE}/PhotoGallery/${isFilter?data.photo_path :data.program_details.photo_url.photo_path
  }`}
 // className="product-image"
   alt={`Image ${index}`}
   style={{ width: '100%',height:"200px", cursor:'pointer',objectFit: 'cover' }}
   //onClick={() => handleImageClick(image)}
 />

      <h3 className="course-title" style={{fontSize:"20px",color:'white'}}>
      <span style={{color:"#fff"}}>{data.product_name}</span></h3> 
        </div>
        </a>
          </div>
          </>
        
      )}
    )
      }
      {isLoadingPhotos && <div>Loading...</div>}
    </div>

</div>
</main>
      </>
    )
  }
  return (
    <WebTemplate>{PhotoGalleryPhotosScreen()}</WebTemplate>
  )
}

export default PhotoGalleryPhotos