import { createSlice } from '@reduxjs/toolkit'

const slice = createSlice({
    name: 'productDetailsData',
    initialState: { product_details_data: null },
    reducers: {
        updateProductDetailsData: (state, { payload: { data } }) => {
            state.product_details_data = data
        },
        clearProductDetailsData: (state, { }) => {
            state.product_details_data = null
        },
    },
})

export const { updateProductDetailsData, clearProductDetailsData } = slice.actions

export default slice.reducer
