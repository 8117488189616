export default build =>
    build.mutation({
        query(payload) {
            console.log("payload",payload)
            return {
                url: `/photogallery/getAllEventsByYearPost?year_id=${payload.year_id}`,
                method: 'POST',
                body: payload,
            };
        }
       
    })
