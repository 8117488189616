import React, { useState, useEffect } from 'react';
import WebTemplate from '../Components/WebTemplate';
import { useParams } from 'react-router-dom';
import { useGetProductByLinkQuery } from '../Services/modules/products';
import { Config } from '../Config';
import DisplayHtmlContent from '../Components/DisplayHtmlContent';

function ProductDetails() {
    const { textAfterSlash } = useParams();
    let product_link = textAfterSlash;

    const { data, isSuccess, isError, isLoading, error } = useGetProductByLinkQuery({ product_link });
    const [currentImage, setCurrentImage] = useState('');
    const [lensPosition, setLensPosition] = useState({ x: 0, y: 0 });
    const [zoomedImagePosition, setZoomedImagePosition] = useState({ left: 0, top: 0 });

    const handlePreviewHover = (imagePath) => {
        setCurrentImage(Config.API_URL_FILE + "/ProductPhotos/" + imagePath);
    };

    const handleMouseMove = (e) => {
        const { left, top, width, height } = e.target.getBoundingClientRect();
        const x = e.clientX - left;
        const y = e.clientY - top;

        setLensPosition({
            x: (x / width) * 100,
            y: (y / height) * 100
        });
    };

    useEffect(() => {
        if (isSuccess) {
            console.log("Product details found", data);
        }
    }, [data, isSuccess]);

    const productDetailsScreen = () => {
        return (
            <>
                {isLoading || data?.length === 0 ? (
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
                        <div className="loader"></div>
                    </div>
                ) : (
                    <>
                        <section className="heroProduct d-flex align-items-center" style={{ height: '60vh' }}>
                            <div className="container">
                                <div className="row" style={{ textAlign: 'center', marginTop: "80px" }}>
                                    <div className="col-lg-12 d-flex flex-column justify-content-center pt-lg-0" data-aos="fade-up" data-aos-delay="200">
                                        <h1 className="text-center text-overlay" style={{ color: 'white' }}>{data.data[0].product_name}</h1>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <main id="main" className='min-vh-100'>
                            <div className='container' style={{ paddingTop: "50px" }}>
                            <h2 className="card-title text-center">{data.data[0].product_name}</h2>

                                <div className='row no-gutters'>
                                    <div className='col-md-12 d-flex justify-content-center align-items-start'>
                                        <div className='card rounded' style={{ maxWidth: '15%'}}>
                                            <img src={currentImage || Config.API_URL_FILE + "/ProductPhotos/" + data.data[0].product_images[0].image_path} alt="Main" className="card-img-top" />
                                           
                                        </div>

                                    </div>
                                    <div className='col-md-12' style={{paddingBottom:"50px"}}>
                                    <div className='card-body'>
                                    <h4>Product Benifits</h4>
                                                <p className="card-text" style={{marginTop:"20px"}}>
                                                    {/* <DisplayHtmlContent htmlContent={data.data[0].product_benefits} /> */}
                                                    {data.data[0].product_benefits}
                                                </p>
                                                <p className="card-text" style={{marginTop:"20px"}}>
                                                    {/* <DisplayHtmlContent htmlContent={data.data[0].product_benefits} /> */}
                                                 <label style={{fontSize:"20px"}}>Packing Details:  </label>  
                                                   {" "+data.data[0].packing_details}
                                                </p>
                                                <p className="card-text" style={{marginTop:"20px"}}>
                                                    {/* <DisplayHtmlContent htmlContent={data.data[0].product_benefits} /> */}
                                                 <label style={{fontSize:"20px"}}>Recommended Dose:  </label>  
                                                   {" "+data.data[0].recommended_dose}
                                                </p>
                                                <p className="card-text" style={{marginTop:"20px"}}>
                                                    {/* <DisplayHtmlContent htmlContent={data.data[0].product_benefits} /> */}
                                                 <label style={{fontSize:"20px"}}>Recommended Crops:  </label>  
                                                   {" "+data.data[0].recommended_crops}
                                                </p>
                                                <p className="card-text" style={{marginTop:"20px"}}>
                                                    <h4>Product Composition</h4>
                                                <table class="table table-bordered">
  <thead>
    <tr>
      <th scope="col">#</th>
      <th scope="col">Name</th>
      <th scope="col">Quantity</th>     
    </tr>
  </thead>
  <tbody>
 {data.data[0].product_composition?.map((data,index)=>{
return(
    <tr>
    <th scope="row">{index+1}</th>
    <td>{data.composition_name}</td>
    <td>{data.composition_quantity}</td>     
  </tr>  
)
 })}
   
  </tbody>
</table>
                                                </p>
                                            </div>
                                    </div>
                                 
                                </div>
                            </div>
                        </main>
                    </>
                )}
            </>
        );
    };

    return (
        <WebTemplate>{productDetailsScreen()}</WebTemplate>
    );
}

export default ProductDetails;