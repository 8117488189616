import React,{useEffect,useRef,useState} from 'react'
import WebTemplate from '../Components/WebTemplate'

import thumb03 from '../assets/img/marva/thumb03.jpg'
import thumb02 from '../assets/img/marva/thumb02.jpg'
import thumb01 from '../assets/img/marva/thumb01.jpg'

import 'glightbox/dist/css/glightbox.min.css';

import { useNavigate } from 'react-router-dom';

import { useDispatch } from 'react-redux';
import { useGetProductsHomePageQuery } from '../Services/modules/products'
import { Config } from '../Config'

function Home() {
  const[product_data,setProductData]=useState([])
  const dispatch=useDispatch()
  const navigate = useNavigate()
  const{
    data,
    isSuccess,
    isLoading,
    error,
    refetch
  }=useGetProductsHomePageQuery()
useEffect(()=>{
  if(isSuccess)
  {
    setProductData(data.data)
  }
},[data])

const goToProductDetails=(param)=>{ 
  navigate({
    pathname: `/productdetails/${param.product_link}`,
  });
 }

    const HomeScreen=()=>{        
        return(
            <>
                        
<section id="hero" class="d-flex align-items-center">

<div class="container">
  <div class="row">
    <div class="col-lg-12 d-flex 
    flex-column justify-content-center  pt-lg-0"
     data-aos="fade-up" data-aos-delay="200">
    
      <h1 class="text-center text-overlay" style={{color:'white'}}>WELCOME TO CONQUER CROP CARE</h1> 
    
      
  
    </div>
  </div>
</div>

</section>
 
              <main id="main" >

<section id="about" class="about">
  <div class="container" data-aos="fade-up">
  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
  <h2 style={{ color: 'black', marginBottom: "0px",fontSize:'25px' }}>
      <span style={{ color: "#ac00ac", fontFamily: 'Algerian, cursive' }}>
      <span style={{ color: "#ac00ac", fontFamily: 'Algerian, cursive'
    
    }}
      > 
    
       </span>
    
      </span>
    </h2>
            

    </div>
     {/* <div class="section-title">
     <video width="50%" heighCARt="50%" controls>
        <source src={teatimecakeclass} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
     </div> */}
  
 
 
    <div class="section-title" style={{marginTop:"10px"}}>
      <h2 style={{color:'black',padding:"0px",marginBottom:"20px"}}>
        <span style={{color:"#6b9c2f"}}>
       WELCOME TO CONQUER CROP CARE</span> </h2>
    </div>

    <div class="row content">
      <div class="col-lg-12">
        <p className='textFontSize' style={{textAlign:"justify",color:'black'}}>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed eget justo nec massa condimentum eleifend. Quisque euismod nisi ut velit eleifend, at aliquet purus aliquam. Nullam vel velit eu ligula consequat feugiat. Fusce euismod justo sed mauris molestie, sed condimentum est ullamcorper. Integer consequat magna nec justo luctus, nec condimentum justo fermentum. Vivamus rutrum odio id ante dictum, nec sodales urna tincidunt. Duis interdum justo vel ante dictum efficitur. Sed vehicula nisi sed elit posuere eleifend. Proin vestibulum justo a justo sodales, ut condimentum ipsum laoreet.


        </p>
        <p className='textFontSize' style={{textAlign:"justify",color:'black'}}>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed eget justo nec massa condimentum eleifend. Quisque euismod nisi ut velit eleifend, at aliquet purus aliquam.
        </p>
      
      </div>
    
   

      
    </div>

  </div>
</section>

<section style={{paddingTop:"1px"}}>

</section>
      <section id="cta" class="cta" >
      <div class="container" data-aos="zoom-in" style={{fontSize:"25px"}}>

        <div class="row">
          <div class="col-lg-9 text-center text-lg-start">
          
            <h3 style={{color:"#00fc7e"}}>Reach us out to order products..
            </h3>
           
          </div>
          <div class="col-lg-3 cta-btn-container text-center">
            <a class="cta-btn align-middle" style={{cursor:'pointer',color:"#00fc7e"}} onClick={()=>navigate("/contact")}>Contact Us</a>
          </div>
        </div>
       

      </div>
    </section>






<section id="services" class="services">

      <div class="container" data-aos="fade-up">

        <div class="section-title" style={{marginBottom:"20px"}}>
          <h2 style={{color:"#6b9c2f",paddingBottom:"5px"}}>
            Our Products</h2>
         
        </div>
        <div class="row">
          {
            product_data.map((info,index)=>{
              console.log("product data info",info)
              if(info.is_homepage==true)
              {
                return(
                  <>
                     <div class="col-xl-4 col-md-6 " 
  style={{marginBottom:"20px"}} data-aos="zoom-in" data-aos-delay="100">
                <a style={{cursor:"pointer"}} onClick={()=>goToProductDetails(info)}>
                <div className="course-card">
               {
                info.product_images.map((image_info,image_index)=>{
                  if(image_info.is_thumbnail_image==true)
                  {   
return(
  <img
                  
  src={`${Config.API_URL_FILE}/ProductPhotos/${image_info.image_path}`}
 // className="product-image"
   alt={`Image ${index}`}
   style={{ width: '25%',height:"10%", cursor:'pointer',objectFit: 'cover' }}
   //onClick={() => handleImageClick(image)}
 />
)
                  }
                })
               }
            
     <h3 className="course-title" style={{fontSize:"20px",color:'white'}}>
      <span style={{color:"#000"}}>{info.product_name}</span></h3>      
  </div>
                
                  </a>             
             </div>
                  </>
                )
              }
            

            })
          }
     
          
        
        </div>

       
     
      </div>
    
      </section>
      {/* <section id="clients" class="clients">

<SuccessVideoSection 
        data={[]} 
         for_course={"home_page"}
         caption={"Testimonials"}
         justifyContent={"left"}
         />
 
</section> */}
      <section id="clients" class="clients" style={{marginBottom:"50px"}}>
    
     

   
    </section>
  
   
</main>
            </>
        )
    }
  return (
  <WebTemplate>
    {HomeScreen()}
  </WebTemplate>
  )
}

export default Home