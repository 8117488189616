import { api } from '../../api'
import getAllPhotos from './getAllPhotos'
import getEventsByYear from './getEventsByYear'

export const galleryApi = api.injectEndpoints({
    endpoints: build => ({
        GetAllPhotos: getAllPhotos(build),
        GetEventsByYear:getEventsByYear(build)
       
    }),
    overrideExisting: true,
})

export const { 
    useGetAllPhotosQuery,
    useGetEventsByYearMutation
   
 } = galleryApi

