export default build =>
    build.query({
        tagTypes: ['allPhotos'],
        keepUnusedDataFor: 1,
        query(payload) {
           // console.log("payload in get all products",payload)            
            return {
                url: `/photogallery/getAllPhotos?page_number=${payload.page_number}&page_size=${payload.page_size}`,
              //  url: `/product/getAllProducts`,
                method: 'GET'              
            };
           
        },
       providesTags: ['allPhotos']
    })
